import { flowRight, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Children, Component } from 'react';
import { connect } from '../runtime-context';

import { PermissionContext } from '../../context/permission-context';
import createPermissionChecker from '../../services/create-permission-checker';
import createPermissionFlags from '../../services/create-permission-flags';
import withAuth from '../../hoc/with-auth';
import { getCurrentUser } from '@wix/communities-blog-client-common';

class PermissionsProvider extends Component {
  permissions = createPermissionChecker(
    this.props.getState(),
    this.props.currentUser,
    this.props.permissionFlags,
    new Date().getTime(),
  );

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.permissionFlags, this.props.permissionFlags)) {
      this.permissions = createPermissionChecker(
        nextProps.getState(),
        nextProps.currentUser,
        nextProps.permissionFlags,
        new Date().getTime(),
      );
    }

    this.permissions.setUserIdentity(nextProps.currentUser);
  }

  render() {
    return (
      <PermissionContext.Provider value={this.permissions}>
        {Children.only(this.props.children)}
      </PermissionContext.Provider>
    );
  }
}

PermissionsProvider.propTypes = {
  getState: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  currentUser: PropTypes.object,
  permissionFlags: PropTypes.object,
};

const mapRuntimeToProps = (state) => ({
  permissionFlags: createPermissionFlags(state),
  currentUser: getCurrentUser(state),
  getState: () => state,
});

export default flowRight(withAuth, connect(mapRuntimeToProps))(PermissionsProvider);
