import { find } from 'lodash';
import { createSelector } from 'reselect';
import { getAppData } from './app-data-selectors';
import { getCategories } from './categories-selectors';
import { getCurrentMatchPathname } from '../router/router-selectors';
import { getTotalPublicationsCount } from '../store/blog-stats/blog-stats-selectors';
import * as getHeaderLinksService from '../services/get-header-links';
import { getCategoryPath } from '@wix/communities-blog-client-common';

export const getFeedAndCategoryLinks = createSelector(
  getCategories,
  getAppData,
  getTotalPublicationsCount,
  getCategoryPath,
  (categories, appData, totalPublicationsCount, categoryPath) =>
    getHeaderLinksService.getHeaderLinks(categories, appData, categoryPath, totalPublicationsCount, true),
);

export const getCategoryLinks = createSelector(
  getCategories,
  getAppData,
  getCategoryPath,
  (categories, appData, categoryPath) =>
    getHeaderLinksService.getHeaderLinks(categories, appData, categoryPath, 0, false),
);

export const getActiveLink = createSelector(
  getFeedAndCategoryLinks,
  getCurrentMatchPathname,
  (links, currentPath) => find(links, (link) => link.path === currentPath) || links[0],
);
